import axios from 'axios'
import qs from "qs";
import {
  Notification
} from 'element-ui'

function logout() {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("utype");
  window.localStorage.removeItem("name");
  window.location.href = "/";
}

//设置基本参数
const service = axios.create({
  baseURL: '/apl', //实际接口url无关,用于匹配proxy的规则
  timeout: 50000, //超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded' //设置请求头的内容类型
  },
})
//请求拦截器
service.interceptors.request.use(config => {
  config.method = "GET"; //将请求设置post方式
  let token = window.localStorage.getItem("token");
  if (token != '') {
    config.data.token = token;
  }
  config.data = qs.stringify(config.data) //将请求json数据格式化为表单querystring形式
  return config;
}, error => {
  return Promise.reject(error)
})
//响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.msg == '您的账号在其他地方登录,请重新登录' || res.msg == '该账号已被加入黑名单,请重新登录' || res.msg == '登录超时,令牌失效,请重新登录') {
      // logout();
    }
    return res;
  },
  error => {
    return Promise.reject(error)
  }
);
export default service

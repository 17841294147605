<template>
    <div style="margin:10px 10px 0 10px;">

        <el-tabs v-model="activeName" style="background: white;padding-left: 10px">
            <el-tab-pane label="省市级联" v-if="proviceShow" name="four">
                <!--搜索-->
                <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px">
                    <el-form ref="searchForm" :model="searchCity" label-width="auto" size="mini" inline>
                        <el-form-item label="层级" prop="name">
                            <el-select v-model="searchCity.level" @change="levelChange" filterable placeholder="请选择"
                                       clearable>
                                <el-option v-for="item in cityLevel" :key="item.id"
                                           :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="名称" prop="name">
                            <el-select v-model="searchCity.name" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in levelData" :key="item.adcode"
                                           :label="item.name" :value="item.adcode">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="primary" size="mini" @click="onSearchCity" icon="el-icon-search">查询
                        </el-button>
                    </el-form>
                </div>

                <!--列表-->
                <div style="margin-top: 10px;height:calc(100vh - 220px)">
                    <!--数据-->
                    <div>
                        <el-table :data="tableDataProvice" border style="width: 100%">
                            <el-table-column prop="id" label="编号"></el-table-column>
                            <el-table-column prop="level" label="层级"></el-table-column>
                            <el-table-column prop="name" label="名称"></el-table-column>
                            <el-table-column prop="mobile" label="是否启用">
                                <template slot-scope="scope">
                                    <el-tag size="mini" v-if="scope.row.status=='0'" type="success">启用</el-tag>
                                    <el-tag size="mini" v-if="scope.row.status=='1'" type="info">禁用</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="date" label="操作">
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.status=='0'" @click="proviceStatus(scope.row,1)"
                                               type="text"
                                               size="small">禁用
                                    </el-button>
                                    <el-button v-if="scope.row.status=='1'" @click="proviceStatus(scope.row,0)"
                                               type="text"
                                               size="small">启用
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div style="margin-top: 25px;text-align: center">
                        <el-pagination layout="total, sizes, prev, pager, next" :current-page="pagec"
                                       :page-sizes="[10, 20, 50]" :page-size="pagesizec"
                                       :total="totalc" @size-change="handleSizeChangec"
                                       @current-change="handleCurrentChangec">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="社区管理" v-if="hospitalShow" name="first">
                <!--搜索-->
                <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px">
                    <el-form ref="searchForm" :model="searchHospital" label-width="auto" size="mini" inline>
                        <el-form-item label="社区名称" prop="name">
                            <el-input v-model="searchHospital.name" clearable/>
                        </el-form-item>
                        <el-form-item label="联系人" prop="name">
                            <el-input v-model="searchHospital.truename" clearable/>
                        </el-form-item>
                        <el-form-item label="手机" prop="name">
                            <el-input v-model="searchHospital.mobile" clearable/>
                        </el-form-item>
                        <el-button type="primary" size="mini" @click="onSearchHospital" icon="el-icon-search">查询
                        </el-button>
                        <el-button type="primary" size="mini" @click="addHospital" icon="el-icon-circle-plus">新增
                        </el-button>
                    </el-form>
                </div>

                <!--列表-->
                <div style="margin-top: 10px;height:calc(100vh - 220px);overflow: auto">
                    <!--数据-->
                    <div>
                        <el-table :data="tableDataHospital" border style="width: 100%">
                            <el-table-column prop="id" width="80" label="编号"></el-table-column>
                            <el-table-column prop="name" label="社区名称"></el-table-column>
                            <el-table-column prop="provice" label="省"></el-table-column>
                            <el-table-column prop="city" label="市"></el-table-column>
                            <el-table-column prop="area" label="区/县"></el-table-column>
                            <el-table-column prop="truename" label="联系人"></el-table-column>
                            <el-table-column prop="mobile" label="手机"></el-table-column>
                            <el-table-column prop="create_time" width="160" label="创建时间"></el-table-column>
                            <el-table-column prop="date" width="100" label="操作">
                                <template slot-scope="scope">

                                    <el-button type="text" size="small" @click="editHospital(scope.row)">编辑</el-button>
                                    <el-button @click="delHospital(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div style="margin-top: 25px;text-align: center">
                        <el-pagination layout="total, sizes, prev, pager, next" :current-page="page2"
                                       :page-sizes="[10, 20, 50]" :page-size="pagesize2"
                                       :total="total2" @size-change="handleSizeChange2"
                                       @current-change="handleCurrentChange2">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="角色管理" v-if="roleShow" name="second">
                <!--搜索-->
                <div class="el-card is-always-shadow"
                     style="padding:20px 0 0 20px;margin-top: 10px;padding-bottom: 20px">
                    <el-button type="primary" size="mini" @click="getRoleData" icon="el-icon-circle-plus">查询</el-button>
                    <el-button type="primary" size="mini" @click="addRole" icon="el-icon-circle-plus">新增</el-button>
                </div>
                <div style="margin-top: 10px;height:calc(100vh - 220px)">
                    <!--数据-->
                    <div>
                        <el-table v-loading="loading" :data="roleDataList" border style="width: 100%">
                            <el-table-column prop="id" label="编号"></el-table-column>
                            <el-table-column prop="name" label="角色名称"></el-table-column>
                            <el-table-column prop="menu_ids" label="编号"></el-table-column>
                            <el-table-column prop="create_at" label="创建时间"></el-table-column>
                            <el-table-column prop="date" label="操作">
                                <template slot-scope="scope">

                                    <el-button type="text" size="small" @click="editRole(scope.row,1)">功能权限</el-button>
                                    <el-button type="text" size="small" @click="editRole(scope.row,2)">数据权限</el-button>
                                    <el-button @click="delRole(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="管理员管理" v-if="userShow" name="three">
                <!--搜索-->
                <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px">
                    <el-form ref="searchForm" :model="searchForm" label-width="auto" size="mini" inline>
                        <el-form-item label="手机号" prop="name">
                            <el-input v-model="searchForm.phone" clearable/>
                        </el-form-item>
                        <el-form-item label="角色" prop="name">
                            <el-select v-model="searchForm.role_id" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in RoleAllData" :key="item.id"
                                           :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="社区" prop="name">
                            <el-select v-model="searchForm.hospital" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in HospitalAllData" :key="item.id"
                                           :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="primary" size="mini" @click="onSearch" icon="el-icon-search">查询
                        </el-button>
                        <el-button type="primary" size="mini" @click="addUser" icon="el-icon-circle-plus">新增
                        </el-button>
                    </el-form>
                </div>
                <div style="margin-top: 10px;height:calc(100vh - 220px);overflow: auto">
                    <!--数据-->
                    <div>
                        <el-table :data="tableData" border style="width: 100%">
                            <el-table-column prop="id" label="编号"></el-table-column>
                            <el-table-column prop="name" label="用户名"></el-table-column>
                            <el-table-column prop="phone" label="登录账号"></el-table-column>
                            <el-table-column prop="role_name" label="角色名称"></el-table-column>
                            <el-table-column prop="hospital_name" label="社区"></el-table-column>
                            <el-table-column prop="create_at" label="创建时间"></el-table-column>
                            <el-table-column prop="date" label="操作">
                                <template slot-scope="scope">

                                    <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>
                                    <el-button @click="delUser(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <div style="margin-top: 25px;text-align: center;margin-bottom: 20px">
                        <el-pagination layout="total, sizes, prev, pager, next" :current-page="page1"
                                       :page-sizes="[10, 20, 50]" :page-size="pagesize1"
                                       :total="total1" @size-change="handleSizeChange1"
                                       @current-change="handleCurrentChange1">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!--角色信息编辑-->
        <el-dialog title="编辑" :visible.sync="roleDialog" width="50%" top="10%">
            <el-form ref="form" :model="role_form" label-width="80px" input-width="50px">
                <el-form-item label="角色名称">
                    <el-input require v-model="role_form.name" minlength="3" style="width: 60%"></el-input>
                </el-form-item>

                <el-form-item label="功能权限">
                    <el-tree
                            ref="tree"
                            :data="menuDataList"
                            show-checkbox
                            node-key="id"
                            :default-checked-keys="selectKey"
                            :props="defaultProps">
                    </el-tree>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="roleDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateRole">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--角色省市级联编辑-->
        <el-dialog title="编辑" :visible.sync="rolePDialog" width="50%" top="10%">
            <el-form ref="form" :model="role_form" label-width="80px" input-width="50px">
                <el-form-item label="数据权限">
                    <el-tree
                            ref="treeP"
                            :data="roleProviceData"
                            show-checkbox
                            node-key="adcode"
                            :default-checked-keys="roleSelectKey"
                            :props="defaultProps">
                    </el-tree>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="rolePDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateRoleP">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--社区信息编辑-->
        <el-dialog title="编辑" :visible.sync="hospitalDialog" width="50%">
            <el-form ref="form" :model="hospital_form" label-width="80px" input-width="50px">
                <el-form-item label="机构名称">
                    <el-input require v-model="hospital_form.name" minlength="3" style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item label="选择地区">
                    <el-select size="mini" style="width: 150px" v-model="hospital_form.provice" filterable
                               placeholder="请选择省" @change="getArea(2)" clearable>
                        <el-option
                                v-for="item in proviceData"
                                :key="item.adcode"
                                :label="item.name"
                                :value="item.adcode">
                        </el-option>
                    </el-select>
                    <el-select size="mini" style="width: 150px" v-model="hospital_form.city" filterable
                               placeholder="请选择市" @change="getArea(3)" clearable>
                        <el-option
                                v-for="item in cityData"
                                :key="item.adcode"
                                :label="item.name"
                                :value="item.adcode">
                        </el-option>
                    </el-select>
                    <el-select size="mini" style="width: 150px" v-model="hospital_form.area" filterable
                               placeholder="请选择区" clearable>
                        <el-option
                                v-for="item in areaData"
                                :key="item.adcode"
                                :label="item.name"
                                :value="item.adcode">
                        </el-option>
                    </el-select>

                </el-form-item>
                <el-form-item label="联系人">
                    <el-input require v-model="hospital_form.truename" minlength="3" style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input require v-model="hospital_form.mobile" minlength="3" style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="hospitalDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateHospital">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--用户信息编辑-->
        <el-dialog title="编辑" :visible.sync="userDialog" width="50%">
            <el-form ref="form" :model="user_form" label-width="80px" input-width="50px">
                <el-form-item label="用户姓名">
                    <el-input require v-model="user_form.name" minlength="3" style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item label="登陆账号">
                    <el-input require v-model="user_form.phone" minlength="3" style="width: 60%"></el-input>
                    （手机号）
                </el-form-item>
                <el-form-item label="登陆密码">
                    <el-input require v-model="user_form.pwd" minlength="3" style="width: 60%"></el-input>
                </el-form-item>

                <el-form-item label="选择社区">
                    <el-select v-model="user_form.hospital" placeholder="请选择" filterable style="width: 60%">
                        <el-option v-for="item in HospitalAllData" :key="item.id"
                                   :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择角色">
                    <el-select v-model="user_form.role_id" placeholder="请选择" style="width: 60%">
                        <el-option v-for="item in roleDataList" :key="item.name"
                                   :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="选择医院">
                    <el-select v-model="user_form.pre1" placeholder="请选择" filterable
                               @change="getDeptList(user_form.pre1)" style="width: 60%">
                        <el-option v-for="item in HospitalList" :key="item.HOSPITAL_CODE"
                                   :label="item.HOSPITAL_NAME" :value="item.HOSPITAL_CODE">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择科室">
                    <el-select v-model="user_form.pre2" placeholder="请选择" filterable style="width: 60%">
                        <el-option v-for="item in DeptList" :key="item.DEPT_CODE"
                                   :label="item.DEPT_NAME" :value="item.DEPT_CODE">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="userDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateUser">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>
<script>
    import request from "../../plugins/axios";
    import requestg from "../../plugins/axiosg";

    export default {
        data() {
            return {
                loading: false,
                proviceData: [],
                cityData: [],
                areaData: [],

                //省市级联
                searchCity: {
                    level: '',
                    name: ''
                },
                levelData: [],
                cityLevel: [{id: '1', name: '一级'}, {id: '2', name: '二级'}, {id: '3', name: '三级'}],
                tableDataProvice: [],
                pagec: 1,
                pagesizec: 10,
                totalc: 0,
                proviceShow: false,
                hospitalShow: false,
                userShow: false,
                roleShow: false,
                activeName: 'three',
                //角色数据
                rolePDialog: false,
                roleProviceData: [],
                roleCityData: [],
                roleAreaData: [],
                defaultRoleProps: {
                    children: 'children',
                    label: 'name'
                },
                roleSelectKey: [],
                roleDataList: [],//角色列表
                roleDialog: false,

                role_form: {
                    id: '',
                    name: '',
                    curd: ''
                },
                menuDataList: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                selectKey: [],

                //用户管理
                searchForm: {},
                HospitalAllData: [],//所有社区
                RoleAllData: [],//所有角色
                user_form: {
                    curd: '',
                    hospital: '',
                    name: '',
                    phone: '',
                    role_id: '',
                    pwd: '',
                    id: '',
                    pre1: '',
                    pre2: ''
                },
                user_page: 0,
                userDialog: false,
                page1: 1,
                total1: 0,
                pagesize1: 10,
                tableData: [],

                //社区
                page2: 1,
                total2: 0,
                pagesize2: 10,
                hospitalDialog: false,
                tableDataHospital: [],
                hospital_form: {
                    name: '',
                    truename: '',
                    mobile: '',
                    provice: '',
                    city: '',
                    area: ''
                },
                searchHospital: {},
                HospitalList: [],
                DeptList: [],

            };
        },
        components: {},
        mounted() {
            let that = this
            that.getUserMenu();
            that.getRoleData();
            that.getMenuData();
            that.getUserData();
            that.getHospitalData();
            that.getHospitalAllData();
            that.getRoleAllData();
            that.getCityData();
            that.getHospitalList()
            this.hrole = localStorage.getItem('hrole')
        },
        methods: {
            getHospitalList() {
                let that = this
                requestg({
                    url: "http://120.46.200.22:1690/hisapi/PatientExamBP/GetHospitalList",
                    data: {},
                }).then((ret) => {
                    that.HospitalList = ret.data
                })
            },
            getDeptList(code) {
                let that = this
                requestg({
                    url: "http://120.46.200.22:1690/hisapi/PatientExamBP/GetHospitalDeptList?HOSPITAL_CODE=" + code,
                    data: {},
                }).then((ret) => {
                    that.DeptList = ret.data
                })
            },
            //省市级联启用禁用
            proviceStatus(row, type) {
                let that = this
                request({
                    url: "/hospital/News/proviceStatus",
                    data: {id: row.id, status: type},
                }).then((ret) => {
                    that.getCityData();
                })
            },
            //层级选中事件
            levelChange() {
                let that = this
                request({
                    url: "/hospital/News/getCity",
                    data: {
                        level: that.searchCity.level
                    },
                }).then((ret) => {
                    that.levelData = ret.data
                })

            },
            getCityData(page) {
                let that = this
                let data = that.searchCity
                data.pagesize = that.pagesizec
                data.page = page ? page : 1;

                request({
                    url: "/hospital/News/city_list",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.tableDataProvice = ret.data.list
                        that.totalc = ret.data.total
                    }
                });
            },
            handleSizeChangec(pageSize) {
                this.pagesize2 = pageSize;
                this.getCityData(1);
            },
            handleCurrentChangec(page) {
                this.getCityData(page);
            },
            onSearchCity() {
                this.getCityData(1)
            },
            //获取目录权限
            getUserMenu() {
                let that = this
                request({
                    url: "/hospital/News/getMenuChildren",
                    data: {
                        parent_id: '5'
                    },
                }).then((ret) => {
                    let data = ret.data
                    let show = '';
                    data.forEach(function (item, index) {
                        if (item.id == '7') {
                            that.userShow = true;
                        }

                        if (item.id == '6') {
                            that.roleShow = true;
                        }

                        if (item.id == '8') {
                            that.hospitalShow = true;
                        }
                        if (item.id == '13') {
                            that.proviceShow = true;
                        }
                        that.activeName = item.tab_pane_name
                    })
                    console.log(ret.data)
                });

            },

            //获取角色列表
            getRoleData() {
                request({
                    url: "/hospital/News/role_list",
                    data: {hp: 2000},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.roleDataList = ret.data;
                    }
                });
            },
            reset() {
                this.menuDataList.forEach(ele => { // 把已选中的复选框清除
                    this.$nextTick(() => {
                        this.$refs.tree.setCheckedKeys([]);
                    })
                })
            },
            //获取菜单
            getMenuData() {

                request({
                    url: "/hospital/News/get_menu_role",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.menuDataList = ret.data;
                    }
                });
            },
            //新增
            addRole() {
                let that = this
                that.reset();
                that.role_form.name = '';
                that.selectKey = [];
                that.role_form.id = '';
                that.role_form.curd = 'c';
                that.roleDialog = true;


            },
            //编辑角色信息
            editRole(row, type) {
                let that = this
                if (type == 1) {
                    that.selectKey = []
                    let menu_ids = row.menu_ids.split(',');
                    that.reset();
                    setTimeout(function () {
                        that.role_form.name = row.name
                        that.selectKey = menu_ids
                        that.role_form.id = row.id;
                        that.role_form.curd = 'u';
                        that.roleDialog = true;
                    }, 1000)
                } else if (type == 2) {
                    //获取省市级联
                    that.loading = true
                    request({
                        url: "/hospital/News/get_area_all",
                        data: {level: '1', parent: 100000},
                    }).then((ret) => {
                        that.roleProviceData = ret.data
                        let pro_ids = [];
                        if (row.pro_ids) {
                            pro_ids = row.pro_ids.split(',');
                        }
                        setTimeout(function () {
                            that.roleSelectKey = pro_ids
                            that.role_form.id = row.id;
                            that.rolePDialog = true;
                            that.loading = false
                        }, 2000)

                    })


                }
            },
            //删除角色
            delRole(row) {
                let that = this
                that.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: "/hospital/News/role_update",
                        data: {curd: 'd', id: row.id, hp: 2000},
                    }).then((ret) => {
                        if (ret.code == 1) {

                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'success'
                            });
                            that.getRoleData();
                            that.roleDialog = false;
                        } else {
                            that.$notify({
                                title: '警告',
                                message: ret.msg,
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                    that.$notify({
                        title: '警告',
                        message: "取消删除",
                        type: 'warning'
                    });
                });


            },
            updateRoleP() {
                let that = this
                let ids = that.$refs.treeP.getCheckedKeys().toString()
                let data = {
                    id: that.role_form.id,
                    pro_ids: ids,
                    curd: 'pu',
                    hp: 2000
                }

                request({
                    url: "/hospital/News/role_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {

                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'success'
                        });
                        that.getRoleData();
                        that.roleDialog = false;
                    } else {
                        that.$notify({
                            title: '警告',
                            message: ret.msg,
                            type: 'warning'
                        });
                    }

                    that.rolePDialog = false;
                });

            },
            updateRole() {
                let that = this
                let ids = that.$refs.tree.getCheckedKeys().toString()
                let role_name = that.role_form.name
                let data = {
                    id: that.role_form.id,
                    ids: ids,
                    name: role_name,
                    curd: that.role_form.curd,
                    hp: 2000
                }

                request({
                    url: "/hospital/News/role_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {

                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'success'
                        });
                        that.getRoleData();
                        that.roleDialog = false;
                    } else {
                        that.$notify({
                            title: '警告',
                            message: ret.msg,
                            type: 'warning'
                        });
                    }
                });

            },
            //社区开始
            getHospitalData(page) {
                let that = this
                let data = that.searchHospital
                data.hp = 2000
                data.pagesize = that.pagesize2
                data.page = page ? page : 1;

                request({
                    url: "/hospital/News/hospital_list",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.tableDataHospital = ret.data.list
                        that.total2 = ret.data.total
                    }
                });
            },
            onSearchHospital() {
                let that = this
                that.getHospitalData();
            },
            handleSizeChange2(pageSize) {
                this.pagesize2 = pageSize;
                this.getHospitalData(1);
            },
            handleCurrentChange2(page) {
                this.getHospitalData(page);
            },
            addHospital() {
                let that = this
                request({
                    url: "/hospital/News/get_area",
                    data: {level: '1', parent: ''},
                }).then((ret) => {
                    that.proviceData = ret.data
                })

                let hospital_form = {
                    id: '',
                    name: '',
                    truename: '',
                    mobile: '',
                    curd: "c",
                    hp: 2000
                }
                that.hospital_form = hospital_form
                that.hospitalDialog = true
            },
            //删除
            delHospital(row) {
                let that = this
                that.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: "/hospital/News/hospital_update",
                        data: {curd: 'd', id: row.id, hp: 2000},
                    }).then((ret) => {
                        if (ret.code == 1) {

                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'success'
                            });
                            that.getHospitalData();
                            that.roleDialog = false;
                        } else {
                            that.$notify({
                                title: '警告',
                                message: ret.msg,
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                    that.$notify({
                        title: '警告',
                        message: "取消删除",
                        type: 'warning'
                    });
                });

            },
            editHospital(row) {
                let that = this
                request({
                    url: "/hospital/News/get_area",
                    data: {level: '1', parent: ''},
                }).then((ret) => {
                    that.proviceData = ret.data
                })
                that.hospital_form = JSON.parse(JSON.stringify(row))
                that.hospital_form.curd = 'u'
                that.hospitalDialog = true
            },
            getArea(level) {
                let that = this
                let parent = that.hospital_form.provice

                if (level == 2) {
                    that.hospital_form.city = '';
                    that.hospital_form.area = '';
                }
                if (level == 3) {
                    parent = that.hospital_form.city
                    that.hospital_form.area = '';
                }


                request({
                    url: "/hospital/News/get_area",
                    data: {level: level, parent: parent},
                }).then((ret) => {
                    if (level == 2) {
                        that.cityData = ret.data
                    }
                    if (level == 3) {
                        that.areaData = ret.data
                    }
                })
            },
            updateHospital() {
                let that = this
                let data = that.hospital_form
                data.hp = 2000;
                request({
                    url: "/hospital/News/hospital_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {

                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'success'
                        });
                        that.getHospitalData();
                        that.hospitalDialog = false;
                    } else {
                        that.$notify({
                            title: '警告',
                            message: ret.msg,
                            type: 'warning'
                        });
                    }
                });
            },


            //用户开始
            getUserData(page) {
                let that = this
                let data = that.searchForm
                data.pagesize = that.pagesize1
                data.page = page ? page : 1;
                data.hp = 2000;

                request({
                    url: "/hospital/News/users_list",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.tableData = ret.data.list
                        that.total1 = ret.data.total
                        that.user_page = ret.data.page
                    }
                });
            },
            //获取所有社区
            getHospitalAllData() {
                let that = this
                let data = that.searchHospital
                data.pagesize = 1000
                data.page = 1;
                request({
                    url: "/hospital/News/hospital_list",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.HospitalAllData = ret.data.list
                    }
                });
            },
            //获取所有角色
            getRoleAllData() {
                let that = this
                request({
                    url: "/hospital/News/role_list",
                    data: {hp: 2000},
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.RoleAllData = ret.data;
                    }
                });
            },
            //用户查询
            onSearch() {
                let that = this.getUserData();
            },
            //新增
            addUser() {
                let that = this
                that.user_form.curd = 'c';
                that.user_form.truename = '';
                that.user_form.name = '';
                that.user_form.mobile = '';
                that.user_form.role = '';
                that.user_form.pre1 = '';
                that.user_form.pre2 = '';

                that.userDialog = true;


            },
            editUser(row) {
                let that = this

                that.user_form.name = row.name
                that.user_form.phone = row.phone
                that.user_form.role_id = row.role_id
                that.user_form.hospital = row.hospital
                that.user_form.id = row.id;
                that.user_form.pre1 = row.pre1;
                that.getDeptList(row.pre1)
                setTimeout(function () {
                    that.user_form.pre2 = row.pre2;
                    that.user_form.curd = 'u';
                    that.userDialog = true;
                }, 1000)
                console.log(that.user_form)
                //


            },
            updateUser() {
                let that = this
                let data = that.user_form
                data.hp = 2000

                if (data.curd == 'c') {
                    if (data.pwd.length < 6) {
                        that.$notify({
                            title: '提示',
                            message: "密码不能低于6位",
                            type: 'warning'
                        });
                        return false;
                    }
                }
                if (data.role_id <= 0) {
                    that.$notify({
                        title: '提示',
                        message: "请选择角色",
                        type: 'warning'
                    });
                    return false;
                } else {
                    if (data.role_id < 11) {

                    } else {
                        if (data.hospital <= 0) {
                            that.$notify({
                                title: '提示',
                                message: "请选择社区",
                                type: 'warning'
                            });
                            return false;
                        }
                    }
                }


                request({
                    url: "/hospital/News/user_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {

                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'success'
                        });
                        that.getUserData(that.user_page);
                        that.userDialog = false;
                    } else {
                        that.$notify({
                            title: '警告',
                            message: ret.msg,
                            type: 'warning'
                        });
                    }
                });

            },
            //删除用户
            delUser(row) {
                let that = this

                that.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: "/hospital/News/user_update",
                        data: {curd: 'd', id: row.id},
                    }).then((ret) => {
                        if (ret.code == 1) {

                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'success'
                            });
                            that.getUserData();
                            that.roleDialog = false;
                        } else {
                            that.$notify({
                                title: '警告',
                                message: ret.msg,
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                    that.$notify({
                        title: '警告',
                        message: "取消删除",
                        type: 'warning'
                    });
                });


            },
            handleSizeChange1(pageSize) {
                this.pagesize1 = pageSize;
                this.getUserData(1);
            },
            handleCurrentChange1(page) {
                this.getUserData(page);
            }
        },
    };
</script>
